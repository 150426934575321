import React from 'react'
import { graphql } from 'gatsby';
import Image from 'gatsby-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';
import Hero from 'components/Hero';
import Projects from 'components/Projects';
import GravityForm from '../components/GravityForm'

import "styles/CaseTemplate.scss";
import { getLanguage } from '../services/language'

const Page = ({ data, location }) => {
  const { vacatures } = data

  console.log(data);

  const url = location.href ? location.href : '';
  const language = data.vacatures.wpml_current_locale.slice(0, 2);
  const { title, acf: { hero, project, subtitle } } = data.vacatures;


  return (
    <Layout>
    <SEO yoast={{ meta: vacatures.yoast_meta }} image={vacatures.acf.banner.localFile.publicURL} lang={language} pathname={url} />

    <div className="klantcase-page">
      <Hero
        className="klantcase-hero"
        fluidImage={hero.image.localFile.childImageSharp.fluid}
        title={title}
        description={hero.description}
        subtitle={subtitle}
      />

      <section className="case">
        <div className="container">
          <div className="row introduction mb-5">
            <div className="col-lg-6">

            <Content className="introduction-content " content={project.introduction} />
            
            {hero.imageSecond && (
              <img src={hero.imageSecond.localFile.publicURL} width="100%" className="mb-5 mb-lg-0"/>
            )}
             
            </div>
            <div className="col-lg-6 pl-0 pr-0">
              <Image
                fixed={project.image.localFile.childImageSharp.fixed}
                className="introduction-image"
              />
            </div>
          </div>
          <div className="row case-content">
            <div className="col-md-12 col-lg-10">
              <Content className="case-content" content={project.post} />
              <h2>Solliciteren</h2>
              <GravityForm
                id={language === 'nl_NL' ? 6 : 6}
                perColumn={4}
                />
            </div>
          </div>
        </div>
      </section>

      {/* {posts.length > 0 && <Projects otherProjects={posts} />} */}
    </div>
  </Layout>
  )
}

export default Page

export const query = graphql`
  query VacatureByID($wordpress_id: Int!) {
   vacatures: wordpressWpVacatures(
        wordpress_id: { eq: $wordpress_id }
    ) {   
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        hero {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        #   imageSecond: image_second {
        #     localFile {
        #       publicURL
        #     }
        #   }
        }

        project: content {
          post
          image {
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 720, height: 487) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          introduction
        }

        banner {
          localFile {
            publicURL
          }
        }
        subtitle
      }
    }
  }
`
